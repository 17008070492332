<template>
	<v-container>
		<div class="text-h5"> {{ tagLabel }}</div>

		<v-row>
			<v-col cols="12" md="6">
				<v-text-field
					v-model="firstName"
					label="First Name"
					:error-messages="firstNameErrors"
					class="required"
					name="firstName"/>

			</v-col>
			<v-col cols="12" md="6">
				<v-text-field
					v-model="lastName"
					label="Last Name"
					:error-messages="lastNameErrors"
					class="required"
					name="lastName"/>
			</v-col>

			<v-col cols="12" md="6">
				<v-combobox
					v-model="teacher"
					:items="teacherOptions"
					label="Teacher/Home Room"
					:error-messages="teacherErrors"
					class="required"
					auto-select-first
					name="teacher"
					v-if="teacherOptions" />

				<v-text-field
					v-model="teacher"
					label="Teacher/Home Room"
					:error-messages="teacherErrors"
					class="required"
					name="teacher"
					v-else />
			</v-col>

			<v-col cols="12" md="6">
				<v-combobox
					v-model="grade"
					:items="gradeOptions"
					label="Grade"
					:error-messages="gradeErrors"
					class="required"
					name="grade"
					v-if="gradeOptions" />

				<v-text-field
					v-model="grade"
					label="Grade"
					:error-messages="gradeErrors"
					class="required"
					name="grade"
					v-else />
			</v-col>

			<v-col cols="12" md="6">
				<v-text-field
					v-model="email"
					label="Email"
					:error-messages="emailErrors"
					name="email"
					:class="{ required: communicationRequired }"/>
			</v-col>

			<v-col cols="12" md="6">
				<v-text-field
					v-model="phoneNumber"
					label="Phone Number"
					:error-messages="phoneNumberErrors"
					name="phoneNumber"
					:class="{ required: communicationRequired }"/>
			</v-col>

			<v-col cols="12">
				<v-text-field
					v-model="studentId"
					label="Student ID"
					name="studentId"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { required, minLength, email, requiredIf } from 'vuelidate/lib/validators';
import { client } from '../utils/axios';

export default {
	props: {
		tagLabel: null,
		projectId: null
	},
	data() {
		return {
			firstName: '',
			lastName: '',
			teacher: '',
			teacherOptions: null,
			grade: '',
			gradeOptions: null,
			studentId: '',
			email: '',
			phoneNumber: '',
			communicationRequired: false
		};
	},
	validations: {
		firstName: { required, minLength: minLength(2) },
		lastName: { required, minLength: minLength(2) },
		teacher: { required, minLength: minLength(2) },
		grade: { required },
		email: { required: requiredIf(function() {
			return this.communicationRequired && !this.phoneNumber;
		}), email },
		phoneNumber: { required: requiredIf(function() {
			return this.communicationRequired && !this.email;
		}) }
	},
	computed: {
		firstNameErrors() {
			const errors = [];
			if(this.$v.firstName.$dirty) {
				this.$v.firstName.required || errors.push('First Name is required');
				this.$v.firstName.minLength || errors.push('First Name must be at least 2 characters long');
			}

			return errors;
		},
		lastNameErrors() {
			const errors = [];
			if(this.$v.lastName.$dirty) {
				this.$v.lastName.required || errors.push('Last Name is required');
				this.$v.lastName.minLength || errors.push('Last Name must be at least 2 characters long');
			}

			return errors;
		},
		teacherErrors() {
			const errors = [];
			if(this.$v.teacher.$dirty) {
				this.$v.teacher.required || errors.push('Teacher is required');
				this.$v.teacher.minLength || errors.push('Teacher must be at least 3 characters long');
			}

			return errors;
		},
		gradeErrors() {
			const errors = [];
			if(this.$v.grade.$dirty) {
				this.$v.grade.required || errors.push('Grade is required');
			}

			return errors;
		},
		emailErrors() {
			const errors = [];
			if(this.$v.email.$dirty) {
				this.$v.email.required || errors.push('Email or Phone Number is required');
				this.$v.email.email || errors.push('Email is not valid');
			}

			return errors;
		},
		phoneNumberErrors() {
			const errors = [];
			if(this.$v.phoneNumber.$dirty) {
				this.$v.phoneNumber.required || errors.push('Phone Number or Email is required');
			}

			return errors;
		}
	},
	watch: {
		firstName() {
			this.updateTags();
		},
		lastName() {
			this.updateTags();
		},
		teacher() {
			this.updateTags();
		},
		grade() {
			this.updateTags();
		},
		studentId() {
			this.updateTags();
		},
		email() {
			this.updateTags();
		},
		phoneNumber() {
			this.updateTags();
		}
	},
	methods: {
		updateTags() {
			let tags = [
				'PROP-First Name: ' + this.firstName,
				'PROP-Last Name: ' + this.lastName,
				'PROP-Teacher: ' + this.teacher,
				'PROP-Grade: ' + this.grade
			];

			if(this.studentId) {
				tags.push('PROP-Student ID: ' + this.studentId);
			}
			if(this.email) {
				tags.push('PROP-Email: ' + this.email);
			}
			if(this.phoneNumber) {
				tags.push('PROP-Phone Number: ' + this.phoneNumber);
			}

			this.$emit('input', tags);
		}
	},
	mounted() {
		if(this.projectId) {
			// Mark as required while querying backend to see if it is
			this.communicationRequired = true;
			client.get(`/api/v1/parent-subject-uploader?projectId=${this.projectId}`).then((response) => {
				this.communicationRequired = response.data.communicationRequired;

				if(response.data.subjectFieldValues?.Teacher && Object.keys(response.data.subjectFieldValues.Teacher).length >= 3) {
					let values = [];
					for(let id in response.data.subjectFieldValues.Teacher) {
						values.push(id);
					}
					values.sort((a, b) => {
						return a.toLowerCase().localeCompare(b.toLowerCase());
					});
					this.teacherOptions = values;
				}
				if(response.data.subjectFieldValues?.Grade && Object.keys(response.data.subjectFieldValues.Grade).length >= 3) {
					let values = [];
					for(let id in response.data.subjectFieldValues.Grade) {
						values.push(id);
					}
					values.sort((a, b) => {
						return a.toLowerCase().localeCompare(b.toLowerCase());
					});
					this.gradeOptions = values;
				}
			}).catch((error) => {
				this.communicationRequired = false;
				console.error(error);
			});
		}
	}
};
</script>

<style>
	.v-input.required .v-label::after {
		content: " *";
		color: red;
	}
</style>