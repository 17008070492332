<template>
	<div class="wrapper">
		<div class="text-h4">Tips for a good portrait photo</div>

		<v-list dense>
			<v-list-item v-for="line in lines" :key="line.en">
				<v-list-item-content class="item">
					* {{ line.en }}
					<v-list-item-subtitle v-if="line.sp">{{ line.sp }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<div class="example-wrapper">
			<div>
				<div class="text-h6">Good</div>
				<img src="~../images/suggested-portrait-photo1.jpg" class="suggested-photos good-photo" />
				<img src="~../images/suggested-portrait-photo2.jpg" class="suggested-photos good-photo" />
			</div>

			<div>
				<div class="text-h6">Bad</div>
				<img src="~../images/bad-portrait-photo1.jpg" class="suggested-photos bad-photo" />
				<img src="~../images/bad-portrait-photo2.jpg" class="suggested-photos bad-photo" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			lines: [
				{
					en: 'Take your photo in a brightly lit area',
					sp: 'Tome su foto en un área muy iluminada'
				},
				{
					en: 'Take your photo against a plain white or solid colored background',
					sp: 'Tome su foto contra un fondo blanco liso o de color sólido'
				},
				{
					en: 'Keep your head centered in the photo',
					sp: 'Mantén tu cabeza centrada en la foto'
				},
				{
					en: 'Look straight at the camera',
					sp: 'Mira directamente a la cámara'
				},
				{
					en: 'No hats, sunglasses, or anything that covers part of your face',
					sp: 'No se permiten sombreros, gafas de sol ni nada que cubra parte de su rostro'
				}
			]
		};
	}	
};
</script>

<style scoped>
	.wrapper {
		margin-bottom: 2em;
	}

	.item {
		justify-content: center;
	}

	.example-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.suggested-photos {
		max-width: 100%;
		max-height: min(48vw, 14em);
		margin: 0.5em;
	}

	.good-photo {
		border: 4px green solid;
	}

	.bad-photo {
		border: 4px red solid;
	}
</style>