<template>
	<v-layout justify-center @drop.prevent @dragover.prevent>
		<v-flex sm12 md10 lg8>
			<uploader
				:title="schoolName"
				:subtitle="subtitle"
				:album-id="albumId"
				complete-message="Your images have been successfully uploaded. Your school has received them and are now in the approval process."
				:embedded-tags="tags"
				tag-label="What is your student's information?"
				class="uploader-page"
				photoCategory="individual_category"
				:allow-multiple="false"
				:choose-file-message="chooseFileMessage"
				:choose-file-header-component="portraitSuggestions"
				:tag-picker-component="subjectNameTags"
				:project-id="projectId"
				:allow-folder-uploads="false" />
		</v-flex>
	</v-layout>
</template>

<script>
import Uploader from '../../PlicCanvas/vue/uploader/Uploader';
import SubjectNameTags from '../../PlicCanvas/vue/uploader/SubjectNameTags';
import PortraitSuggestions from '../../PlicCanvas/vue/uploader/PortraitSuggestions';

export default {
	name: 'UploaderPage',
	data() {
		let embeddedTags = this.$route.query.embeddedTags;
		if(embeddedTags && typeof embeddedTags === 'string') {
			embeddedTags = embeddedTags.split(',');
		}

		return {
			albumId: this.$route.params.id || this.$route.query.album,
			schoolName: this.$route.query.schoolName,
			projectName: this.$route.query.projectName,
			projectId: this.$route.query.projectId,
			tags: embeddedTags,
			chooseFileMessage: 'Select a single jpg or png to upload (up to {{maxFileSize}} each)',
			subjectNameTags: SubjectNameTags,
			portraitSuggestions: PortraitSuggestions
		};
	},
	computed: {
		subtitle() {
			return 'Upload your student\'s portait photo for ' + this.schoolName + ' - ' + this.projectName;
		}
	},
	components: {
		Uploader
	}
}
</script>

<style scoped>
	.uploader-page {
		width: 100%;
		z-index: 1;
	}

	.app-logo {
		max-height: 20vh;
	}

	@media all and (orientation:portrait) {
		.app-logo {
			max-width: 90%;
		}
	}

	@media all and (orientation:landscape) {
		.app-logo {
			max-width: 40%;
		}
	}

	.uploader-page {
		margin-top: 1em;
	}
</style>