import '../PlicCanvas/vue/utils/polyfills';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from '../js/plugins/vuetify';
import Vuelidate from 'vuelidate';
import '../js/mixins/index';
require('intersection-observer-polyfill/dist/IntersectionObserver.global');
import '../PlicCanvas/vue/utils/registerServiceWorker';
import '../PlicCanvas/vue/utils/external-scripts';
require('../PlicCanvas/vue/common/locales');

Vue.config.productionTip = Vue.config.devtools = false;
Vue.use(Vuelidate);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
