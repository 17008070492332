<template>
	<v-layout justify-center align-center>
		<div class="landing-page white--text">
			<img class="app-logo" alt="PLIC Elements" :src="largeLogoUrl">
			<h1>PLIC Elements</h1>
			<div class="subheading">Join us in our quest for world domination by logging in below!</div>
		</div>
	</v-layout>
</template>

<script>

export default {
	name: 'LandingPage'
}
</script>

<style scoped>
	.landing-page {
		width: 100%;
		z-index: 1;
	}

	@media all and (orientation:portrait) {
		.app-logo {
			max-width: 90%;
		}
	}

	@media all and (orientation:landscape) {
		.app-logo {
			max-width: 40%;
			margin-top: 4em;
		}

		.landing-page {
			align-self: flex-start;
		}
	}

	.register-button {
		margin-top: 2em;
	}
</style>